import React from 'react'
import LegalPageLayout from '../../components/LegalPageLayout/legalPageLayout'

const Cookies = () => {

    const bodyContent = () => <div>
                        
                        <p>Cookies are very small text files that are stored on your computer when you visit some websites.
						We use cookies to help identify your computer so we can track anonymous usage statistics to help improve your website experience.
						You can disable any cookies already stored on your computer, but these may stop our website from functioning properly.</p>
						<h3>Functional Cookies</h3>
						<p>This Website will track the pages you visits via Google Analytics</p>
						 
						<h3>Targeting Cookies</h3>
						<p>This Website will allow you to share pages with social networks such as LinkedIn, Facebook and Twitter</p>
						 
						<p>This website will not share any personal information with third parties.</p>
						<p>&nbsp;</p>
                        <p>Issued: January {new Date().getFullYear()}</p>

    </div>


    return (
        <div>
            <LegalPageLayout 
            bannerImg='/img/careers-hub.jpg'
            bannerTitle='Cookies'
            bodyContent={bodyContent()}
            />
        </div>
    )
}

export default Cookies
